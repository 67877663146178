import React from "react";
import { URLS } from "../urls";
import moment from "moment";
import DatePicker from "react-datetime";
import { Form, Col, Row, Image } from "react-bootstrap";
import Select from "react-select";
import { branch_id } from "../../utils";

export default class ApplyLeaveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Submit",
      isWaiting: false,
      applyLeave: {
        employee: {
          id: null,
          name: "",
          image: "",
          designation: "",
        },
        leaveId: "",
        name: "",
        from: moment(new Date()).format("DD/MM/YYYY"),
        to: moment(new Date()).format("DD/MM/YYYY"),
        days: 0,
        remaining: 0,
        reason: "",
        status: "New",
        appliedDate: "",
        branch_id,
      },
      employees: [],
      mapEmployees: [],
      leaveBalances: {},
      employeeAvailableLeaves: null,
    };
  }
  componentWillReceiveProps(props) {
    if (props.employees.length) {
      const emp = props.employees.map((em) => ({
        label: em?.name,
        value: em?.id,
      }));
      this.setState({
        employees: props.employees,
        mapEmployees: emp,
      });
    }
    if (props.leaveBalances) {
      this.setState({
        leaveBalances: props.leaveBalances,
      });
    }
  }
  updateLeaveEmployee = (e) => {
    const { applyLeave, employees, leaveBalances } = this.state;
    const id = e;
    applyLeave.employee = employees.find((employee) => employee.id === id);
    console.log("YOYO", leaveBalances);
    this.setState({
      applyLeave,
      employeeAvailableLeaves: leaveBalances[id],
    });
  };
  updateLeaveName = (e) => {
    const { applyLeave, employeeAvailableLeaves } = this.state;
    applyLeave.leaveId = e.target.value;
    const leaveType = employeeAvailableLeaves.find(
      (l) => l._id == applyLeave.leaveId
    );
    applyLeave.name = leaveType.name;
    applyLeave.remaining = leaveType.remaining;
    applyLeave.days = applyLeave.remaining ? 1 : 0;
    this.setState({
      applyLeave,
    });
  };
  updateLeaveFrom = (event) => {
    const { applyLeave } = this.state;
    applyLeave.from = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    const diff = moment(applyLeave.to, "DD/MM/YYYY").diff(
      moment(applyLeave.from, "DD/MM/YYYY"),
      "days"
    );
    if (diff < 0) {
      applyLeave.to = applyLeave.from;
      applyLeave.days = applyLeave.remaining ? 1 : 0;
    } else {
      applyLeave.days = diff + 1;
    }
    this.setState({
      applyLeave,
    });
  };
  updateLeaveTo = (event) => {
    const { applyLeave } = this.state;
    applyLeave.to = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    applyLeave.days =
      moment(applyLeave.to, "DD/MM/YYYY").diff(
        moment(applyLeave.from, "DD/MM/YYYY"),
        "days"
      ) + 1;
    this.setState({
      applyLeave,
    });
  };
  updateLeaveDays = (e) => {
    const { applyLeave } = this.state;
    applyLeave.days = e.target.value;
    this.setState({
      applyLeave,
    });
  };
  updateLeaveRemaining = (e) => {
    const { applyLeave } = this.state;
    applyLeave.remaining = e.target.value;
    this.setState({
      applyLeave,
    });
  };
  updateLeaveReason = (e) => {
    const { applyLeave } = this.state;
    applyLeave.reason = e.target.value;
    this.setState({
      applyLeave,
    });
  };

  submit = (e) => {
    e.preventDefault();
    this.setState({ buttonText: "Submitting ...", isWaiting: true });
    const { applyLeave } = this.state;
    applyLeave.appliedDate = new Date().getTime();
    const token = "dummy token";
    fetch(`${URLS.backendApplyLeaves}/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ applyLeave }),
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          setTimeout(() => {
            window.$("#add_leave").modal("toggle");
            this.setState({
              buttonText: "Submit",
              isWaiting: false,
              applyLeave: {
                employee: {
                  id: null,
                  name: "",
                  image: "",
                  designation: "",
                },
                leaveId: "",
                name: "",
                from: moment(new Date()).format("DD/MM/YYYY"),
                to: moment(new Date()).format("DD/MM/YYYY"),
                days: 0,
                remaining: 0,
                reason: "",
                status: "New",
                appliedDate: "",
              },
            });
            this.props.updateAppliedLeavesList(result.applyLeave);
          }, 500);
        });
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
          this.setState({ buttonText: "Submit", isWaiting: false });
        });
      }
    });
  };
  validationFrom = (currentDate) => {
    return currentDate.isAfter(moment(new Date()).add(-1, "days"));
  };
  validationTo = (currentDate) => {
    const { applyLeave } = this.state;
    if (applyLeave.from) {
      return (
        currentDate.isBefore(
          moment(applyLeave.from, "DD/MM/YYYY").add(
            applyLeave.remaining,
            "days"
          )
        ) &&
        currentDate.isAfter(
          moment(applyLeave.from, "DD/MM/YYYY").add(-1, "days")
        )
      );
    } else return currentDate.isAfter(moment(new Date()).add(-1, "days"));
  };
  disableDate = (currentDate) => {
    return false;
  };

  render() {
    const { applyLeave, employeeAvailableLeaves, mapEmployees } = this.state;
    console.log("TOTO", employeeAvailableLeaves);
    // const {employees} = this.props;
    return (
      <div id="add_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Apply Leave</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={this.submit}>
                <div className="form-group">
                  <label>
                    Employee<span className="text-danger">*</span>
                  </label>
                  {/* <Form.Control
                    as="select"
                    value={applyLeave?.employee?.id}
                    onChange={this.updateLeaveEmployee}
                    style={{ height: "35px" }}
                  >
                    <option value="">Select Employee</option>
                    {employees?.length
                      ? employees?.map((employee) => {
                          return (
                            <option value={employee?.id}>
                              {employee?.name}
                            </option>
                          );
                        })
                      : ""}
                  </Form.Control> */}
                  {mapEmployees && (
                    <Select
                      closeMenuOnSelect={true}
                      isSearchable
                      required
                      classNamePrefix="form-control"
                      value={mapEmployees?.find(
                        (el) => el.value === applyLeave?.employee?.id
                      )}
                      onChange={(selected) => {
                        if (!selected.value) return;
                        this.updateLeaveEmployee(selected.value);
                      }}
                      options={mapEmployees}
                    />
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    as="select"
                    value={applyLeave._id}
                    onChange={this.updateLeaveName}
                    style={{ height: "35px" }}
                    disabled={applyLeave?.employee?.id ? false : true}
                  >
                    <option value="">Select Type</option>
                    {employeeAvailableLeaves &&
                    employeeAvailableLeaves.length > 0 ? (
                      employeeAvailableLeaves.map((leaveType) => {
                        return (
                          <option value={leaveType._id}>
                            {leaveType.name}
                          </option>
                        );
                      })
                    ) : (
                      <option value="a" disabled>
                        No Leaves Available
                      </option>
                    )}
                  </Form.Control>
                </div>
                <div className="form-group">
                  <label>
                    From <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      value={applyLeave.from}
                      closeOnSelect={true}
                      onChange={this.updateLeaveFrom}
                      isValidDate={
                        applyLeave?.employee?.id
                          ? this.validationFrom
                          : this.disableDate
                      }
                      required={true}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    To <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      value={applyLeave.to}
                      closeOnSelect={true}
                      onChange={this.updateLeaveTo}
                      isValidDate={
                        applyLeave?.employee?.id
                          ? this.validationTo
                          : this.disableDate
                      }
                      required={true}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    disabled
                    type="number"
                    value={applyLeave.days}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Remaining Leaves <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    disabled
                    type="number"
                    value={applyLeave.remaining}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Leave Reason <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows="4"
                    className="form-control"
                    value={applyLeave.reason}
                    onChange={this.updateLeaveReason}
                    disabled={applyLeave?.employee?.id ? false : true}
                    required
                  ></textarea>
                </div>
                <div className="submit-section">
                  <button
                    className="btn add-btn"
                    disabled={!applyLeave.remaining}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
