const BenefitReducer = (
  state = {
    benefits: [],
  },
  action
) => {
  switch (action.type) {
    case "ADD_BENEFITS":
      return { benefits: action.payload };

    case "DELETE_BENEFIT":
      const slug = action.payload;
      let benefits = state.benefits;
      benefits = benefits.filter((benefit) => benefit.slug !== slug);
      return {
        ...state,
        benefits,
      };

    case "ADD_BENEFIT":
      const states = [...state.benefits].filter(
        (el) => el.slug !== action.payload.slug
      );
      return {
        ...state,
        benefits: [...states, action.payload],
      };

    default:
      return state;
  }
};

export default BenefitReducer;
