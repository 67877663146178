import React from "react";

export function PendingCheckIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6V10L13 13M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
        stroke="#E9DB94"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function DisapprovedCheckIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM10 8.586L12.828 5.757L14.243 7.172L11.414 10L14.243 12.828L12.828 14.243L10 11.414L7.172 14.243L5.757 12.828L8.586 10L5.757 7.172L7.172 5.757L10 8.586Z"
        fill="#DA7777"
      />
    </svg>
  );
}

export function ApproveCheckIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 10L9 12L13 8M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
        stroke="#85CC8B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function NoSelectedItemIcon() {
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="72.75" height="72.75" rx="36.375" fill="#ECF4FE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.2598 25.2891H26.0514L23.5254 30.3462V46.7604C23.5254 47.0954 23.6585 47.4167 23.8953 47.6535C24.1322 47.8904 24.4534 48.0235 24.7884 48.0235H47.5228C47.8578 48.0235 48.179 47.8904 48.4159 47.6535C48.6527 47.4167 48.7858 47.0954 48.7858 46.7604V30.3412L46.2598 25.2891ZM44.6987 27.8151L45.9617 30.3412H26.3508L27.6138 27.8151H44.6987ZM36.6832 41.3432H35.339V41.2383C35.3471 39.7851 35.7588 39.337 36.4733 38.893C36.9618 38.5862 37.3372 38.1987 37.3372 37.6335C37.3372 36.9998 36.8407 36.588 36.2231 36.588C35.6539 36.588 35.1049 36.9513 35.0686 37.7102H33.6315C33.6719 36.1763 34.8183 35.3932 36.2311 35.3932C37.7731 35.3932 38.8348 36.249 38.8348 37.6134C38.8348 38.5377 38.3706 39.1432 37.6278 39.5873C36.9698 39.9909 36.6913 40.3825 36.6832 41.2383V41.3432ZM36.9335 42.974C36.9295 43.4665 36.5218 43.8621 36.0454 43.8621C35.553 43.8621 35.1533 43.4665 35.1574 42.974C35.1533 42.4896 35.553 42.094 36.0454 42.094C36.5218 42.094 36.9295 42.4896 36.9335 42.974Z"
        fill="#2463AE"
      />
    </svg>
  );
}

export function SummaryChartIconUp(props) {
  return (
    <svg
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.2665 0.516667C17.1819 0.313042 17.0201 0.151229 16.8165 0.0666666C16.7163 0.0239658 16.6087 0.00131666 16.4998 0H12.3331C12.1121 0 11.9001 0.0877975 11.7439 0.244078C11.5876 0.400358 11.4998 0.61232 11.4998 0.833333C11.4998 1.05435 11.5876 1.26631 11.7439 1.42259C11.9001 1.57887 12.1121 1.66667 12.3331 1.66667H14.4915L9.83312 6.325L7.09145 3.575C7.01398 3.49689 6.92182 3.4349 6.82027 3.39259C6.71872 3.35028 6.6098 3.3285 6.49979 3.3285C6.38978 3.3285 6.28086 3.35028 6.17931 3.39259C6.07776 3.4349 5.98559 3.49689 5.90812 3.575L0.90812 8.575C0.830013 8.65247 0.768017 8.74464 0.72571 8.84619C0.683403 8.94774 0.661621 9.05666 0.661621 9.16667C0.661621 9.27668 0.683403 9.3856 0.72571 9.48715C0.768017 9.5887 0.830013 9.68086 0.90812 9.75833C0.985589 9.83644 1.07776 9.89844 1.17931 9.94074C1.28086 9.98305 1.38978 10.0048 1.49979 10.0048C1.6098 10.0048 1.71872 9.98305 1.82027 9.94074C1.92182 9.89844 2.01398 9.83644 2.09145 9.75833L6.49979 5.34167L9.24145 8.09167C9.31892 8.16977 9.41109 8.23177 9.51264 8.27408C9.61419 8.31638 9.72311 8.33817 9.83312 8.33817C9.94313 8.33817 10.0521 8.31638 10.1536 8.27408C10.2551 8.23177 10.3473 8.16977 10.4248 8.09167L15.6665 2.84167V5C15.6665 5.22101 15.7543 5.43298 15.9105 5.58926C16.0668 5.74554 16.2788 5.83333 16.4998 5.83333C16.7208 5.83333 16.9328 5.74554 17.089 5.58926C17.2453 5.43298 17.3331 5.22101 17.3331 5V0.833333C17.3318 0.724436 17.3092 0.616852 17.2665 0.516667Z"
        fill="#21D589"
      />
    </svg>
  );
}

export function SummaryCloseIcon(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 21.1782L24.125 25.3032L25.3033 24.1249L21.1783 19.9999L25.3033 15.8749L24.125 14.6966L20 18.8216L15.875 14.6966L14.6967 15.8749L18.8217 19.9999L14.6967 24.1249L15.875 25.3032L20 21.1782Z"
        fill="#94A3B8"
      />
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#E2E8F0" />
    </svg>
  );
}

export function ApproveBlueModalIcon(props) {
  return (
    <svg
      {...props}
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 2.63014C14.5 1.17755 15.6572 0 17.0846 0H43.4692L49.3385 6.0274L56.5 13.2055V53.3699C56.5 54.8224 55.3428 56 53.9154 56H17.0846C15.6572 56 14.5 54.8224 14.5 53.3699V2.63014Z"
        fill="#CFE4FC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8311 21.7533C19.8311 20.8152 20.5784 20.0547 21.5003 20.0547H46.3772C47.2991 20.0547 48.0464 20.8152 48.0464 21.7533C48.0464 22.6914 47.2991 23.4519 46.3772 23.4519H21.5003C20.5784 23.4519 19.8311 22.6914 19.8311 21.7533Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8311 31.7816C19.8311 30.8435 20.5784 30.083 21.5003 30.083H46.3772C47.2991 30.083 48.0464 30.8435 48.0464 31.7816C48.0464 32.7198 47.2991 33.4803 46.3772 33.4803H21.5003C20.5784 33.4803 19.8311 32.7198 19.8311 31.7816Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8311 41.8085C19.8311 40.8704 20.5784 40.1099 21.5003 40.1099H46.3772C47.2991 40.1099 48.0464 40.8704 48.0464 41.8085C48.0464 42.7466 47.2991 43.5071 46.3772 43.5071H21.5003C20.5784 43.5071 19.8311 42.7466 19.8311 41.8085Z"
        fill="white"
      />
      <path
        d="M56.4985 13.2055L43.4678 0V10.6192C43.4678 12.0761 44.6315 13.2554 46.0631 13.2494L56.4985 13.2055Z"
        fill="#94A3B8"
      />
      <circle cx="15.5" cy="41" r="15" fill="#2463AE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0519 36.4494C22.6373 37.0485 22.6373 38.02 22.0519 38.6191L14.7711 46.0712C14.1857 46.6703 13.2366 46.6703 12.6512 46.0712L9.43905 42.7835C8.85365 42.1843 8.85365 41.2129 9.43905 40.6138C10.0244 40.0146 10.9736 40.0146 11.559 40.6138L13.7111 42.8165L19.932 36.4494C20.5174 35.8502 21.4665 35.8502 22.0519 36.4494Z"
        fill="#F4F7FA"
      />
    </svg>
  );
}

export const ExcelIcon = ({ color, size }) => {
  return (
    <svg
      style={{
        width: `${size ? size : 24}px`,
        height: `${size ? size : 24}px`,
      }}
      viewBox="0 0 24 24"
    >
      <path
        fill={color ? color : "currentColor"}
        d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z"
      />
    </svg>
  );
};

export const PDFIcon = ({ color, size }) => {
  return (
    <svg
      style={{
        width: `${size ? size : 24}px`,
        height: `${size ? size : 24}px`,
      }}
      viewBox="0 0 24 24"
    >
      <path
        fill={color ? color : "currentColor"}
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M9.5 11.5C9.5 12.3 8.8 13 8 13H7V15H5.5V9H8C8.8 9 9.5 9.7 9.5 10.5V11.5M14.5 13.5C14.5 14.3 13.8 15 13 15H10.5V9H13C13.8 9 14.5 9.7 14.5 10.5V13.5M18.5 10.5H17V11.5H18.5V13H17V15H15.5V9H18.5V10.5M12 10.5H13V13.5H12V10.5M7 10.5H8V11.5H7V10.5Z"
      />
    </svg>
  );
};

export function ExportIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.875 7.8125L16.8744 3.12562L12.1875 3.125"
        stroke="#0F172A"
        stroke-width="1.5"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path
        d="M11.2478 8.75244L16.8728 3.12744"
        stroke="#0F172A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.375 11.25V16.25C14.375 16.4158 14.3092 16.5747 14.1919 16.6919C14.0747 16.8092 13.9158 16.875 13.75 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V6.25C3.125 6.08424 3.19085 5.92527 3.30806 5.80806C3.42527 5.69085 3.58424 5.625 3.75 5.625H8.75"
        stroke="#0F172A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
