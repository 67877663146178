import React, { useState, Ref, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import { addAllSettings } from "./settingsAction";
import Avatar from "../../Avatar";

function Settings(props) {
  const [settings, setSettings] = useState({
    companyName: "",
    companyAddress: "",
    debitAccountNumber: "",
    debitBankCode: "",
    file: null,
    companyEmail: "",
    companyPhone: "",
    companyBranchAddress: "",
  });

  const [btnText, setBtnText] = useState("Save Settings");

  useEffect(() => {
    setSettings(props.settings);
    return () => {};
  }, [props]);

  const saveSettings = async (e) => {
    e.preventDefault();
    try {
      setBtnText("please wait...");
      const token = "dummy token";
      const formData = new FormData();

      for (let setting in settings) {
        formData.append(setting, settings[setting]);
      }

      const response = await fetch(
        `${URLS.backendCompanySettings}/save-settings`,
        {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      const result = await response.json();
      if (result.success) {
        props.addAllSettings(result.settings);
        toast("Settings Saved", {
          type: "success",
        });
      }
    } catch (e) {
      console.log(e);
      toast("Unable to save settings", {
        type: "error",
      });
    } finally {
      setBtnText("Save settings");
    }
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12 col-12 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Settings</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-sm-5 col-5"></div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <form onSubmit={(e) => saveSettings(e)}>
                    <h3 className="page-title">Company Settings</h3>
                    {/* <div className="border">
                      <Avatar
                        className="image"
                        name="companyLogo"
                        onFileUpload={(e) =>
                          setSettings({
                            ...settings,
                            [e.target.name]: e.target.files[0],
                          })
                        }
                        nameInput="file"
                        accept="image/*"
                      />
                    </div> */}

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Company Name</label>
                          <input
                            onChange={(e) =>
                              setSettings({
                                ...settings,
                                [e.target.name]: e.target.value,
                              })
                            }
                            className="form-control"
                            type="text"
                            name="companyName"
                            value={settings?.companyName || ""}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Bank Name</label>
                          <input
                            onChange={(e) =>
                              setSettings({
                                ...settings,
                                [e.target.name]: e.target.value,
                              })
                            }
                            className="form-control"
                            name="bankName"
                            value={settings?.bankName || ""}
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Payroll Debit Account Number</label>
                          <input
                            onChange={(e) =>
                              setSettings({
                                ...settings,
                                [e.target.name]: e.target.value,
                              })
                            }
                            className="form-control"
                            name="debitAccountNumber"
                            value={settings?.debitAccountNumber || ""}
                            type="number"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Payroll Debit bank Code</label>
                          <input
                            onChange={(e) =>
                              setSettings({
                                ...settings,
                                [e.target.name]: e.target.value,
                              })
                            }
                            className="form-control"
                            name="debitBankCode"
                            value={settings.debitBankCode || ""}
                            type="number"
                          />
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Address</label>
                          <input
                            className="form-control"
                            value={settings.companyAddress || ""}
                            name="companyAddress"
                            onChange={(e) =>
                              setSettings({
                                ...settings,
                                [e.target.name]: e.target.value,
                              })
                            }
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label for="companyLogo">Company Logo</label>
                          <input
                            id="companyLogo"
                            className="form-control "
                            type="file"
                            name="file"
                            accept="image/*"
                            onChange={(e) =>
                              setSettings({
                                ...settings,
                                [e.target.name]: e.target.files[0],
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            onChange={(e) =>
                              setSettings({
                                ...settings,
                                [e.target.name]: e.target.value,
                              })
                            }
                            className="form-control"
                            name="companyEmail"
                            value={settings.companyEmail || ""}
                            type="email"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Phone</label>
                          <input
                            onChange={(e) =>
                              setSettings({
                                ...settings,
                                [e.target.name]: e.target.value,
                              })
                            }
                            className="form-control"
                            name="companyPhone"
                            value={settings.companyPhone || ""}
                            type="phone"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Branch Address</label>
                          <input
                            onChange={(e) =>
                              setSettings({
                                ...settings,
                                [e.target.name]: e.target.value,
                              })
                            }
                            className="form-control"
                            name="companyBranchAddress"
                            value={settings.companyBranchAddress || ""}
                            type="address"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="submit-section">
                      <button className="btn btn-primary submit-btn">
                        {btnText}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            }
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
    settings: state.settingsReducer.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAllSettings: (settings) => dispatch(addAllSettings(settings)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Settings));
