import React, { useState, useEffect } from "react";

export default function Avatar({
  name,
  onFileUpload,
  nameInput,
  image,
  extralText,
  ...props
}) {
  const [imageurl, setImageUrl] = useState("");

  useEffect(() => {
    image && setImageUrl(image);
  }, [image]);
  return (
    <div className="rounded-circle" {...props}>
      {!imageurl ? (
        <div
          className="d-flex align-items-center justify-content-center w-100 h-100"
          title={extralText ? extralText : `${name}}`}
        >
          <label className="d-flex align-items-center justify-content-center  p-cursor w-100 h-100">
            {onFileUpload && (
              <input
                type="file"
                onChange={onFileUpload}
                name={nameInput}
                className="d-none"
              />
            )}
            {extralText ? (
              <small className="text-center fw-bold">{extralText}</small>
            ) : (
              <div className="fw-bold fs-6">
                {name ? name.split("")[0].toUpperCase() : "..."}
              </div>
            )}
          </label>
        </div>
      ) : (
        <label className="text-center d-flex  w-100 h-100">
          {onFileUpload && (
            <input
              type="file"
              onChange={onFileUpload}
              name={nameInput}
              className="d-none"
            />
          )}
          <img
            src={imageurl}
            alt="pics"
            className="w-100 h-100 img-fluid rounded"
          />
        </label>
      )}
    </div>
  );
}
