import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import ModalLoader from "../ModalLoader";
import { toast } from "react-toastify";
import { URLS } from "../urls";
import { branch_id } from "../../utils";
import ImportPaylistFromExcel from "../editEmployeeJobGrade/ImportPaylistFromExcel";
import { addAllSettings } from "../settings/settingsAction";

const monthCycleOptions = [
  {
    label: "Previous Month",
    value: "Previous Month",
  },
  {
    label: "Current Month",
    value: "Current Month",
  },
];

function PayrollSettings(props) {
  const defaultSettings = {
    startDay: 1,
    startMonth: "Current Month",
    endDay: 30,
    endMonth: "Current Month",
  };
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({
    ...defaultSettings,
  });

  const [paySetting, setPaySetting] = useState({
    payType: "Net Pay",
    workingDays: 0,
  });

  const [btnText, setBtnText] = useState("Save Pay Settings");

  useEffect(() => {
    getSettings();
    return () => {};
  }, []);

  useEffect(() => {
    setPaySetting(props.settings);
    return () => {};
  }, [props]);

  const getSettings = () => {
    setLoading(true);
    const token = "dummy token";
    fetch(`${URLS.backendPayroll}/get-settings/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result?.settings) {
          setSettings({ ...defaultSettings, ...result.settings });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast("Unable to get Settings", {
          type: "error",
        });
      });
  };

  const saveSettings = (e) => {
    e.preventDefault(e);
    setLoading(true);
    const token = "dummy token";
    fetch(`${URLS.backendPayroll}/save-settings`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ settings, branch_id }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("Successs", {
            type: "success",
          });
        } else {
          throw Error();
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast("Unable to save", {
          type: "error",
        });
      });
  };

  const savePaySetting = async (e) => {
    e.preventDefault();
    try {
      setBtnText("please wait...");
      const token = "dummy token";
      const formData = new FormData();

      for (let setting in paySetting) {
        formData.append(setting, paySetting[setting]);
      }

      const response = await fetch(
        `${URLS.backendCompanySettings}/save-settings`,
        {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      const result = await response.json();
      if (result.success) {
        props.addAllSettings(result.settings);
        toast("Settings Saved", {
          type: "success",
        });
      }
    } catch (e) {
      console.log(e);
      toast("Unable to save settings", {
        type: "error",
      });
    } finally {
      setBtnText("Save Pay settings");
    }
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12">
                <h4 className="page-title">Payroll Settings</h4>
              </div>
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div className="p-3">
              <Form onSubmit={(e) => saveSettings(e)}>
                <Form.Group>
                  <Form.Label>Payroll Cycle</Form.Label>
                  <Row>
                    <Col>
                      <Form.Label>Start Day</Form.Label>
                      <Form.Control
                        type="number"
                        className="mb-2"
                        placeholder=""
                        name="startDay"
                        value={settings.startDay}
                        onChange={(e) => {
                          setSettings({
                            ...settings,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        required
                      />
                      <Select
                        name="startMonth"
                        options={monthCycleOptions}
                        value={monthCycleOptions.find(
                          (el) => el.value == settings.startMonth
                        )}
                        onChange={(selected) => {
                          if (!selected.value) return;
                          setSettings({
                            ...settings,
                            startMonth: selected.value,
                          });
                        }}
                      />
                    </Col>

                    <Col>
                      <Form.Label>End Day</Form.Label>
                      <Form.Control
                        type="number"
                        className="mb-2"
                        placeholder=""
                        name="endDay"
                        value={settings.endDay}
                        onChange={(e) => {
                          setSettings({
                            ...settings,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        required
                      />
                      <Select
                        name="endMonth"
                        options={monthCycleOptions}
                        value={monthCycleOptions.find(
                          (el) => el.value == settings.endMonth
                        )}
                        onChange={(selected) => {
                          if (!selected.value) return;
                          setSettings({
                            ...settings,
                            endMonth: selected.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Button disabled={loading} type="submit">
                  Save
                </Button>
              </Form>
            </div>

            <hr />
            <div className="p-3">
              <div className="row">
                <div className="col-sm-6">
                  <label for="Prorated">Prorated By: </label>
                  <select
                    className="form-control"
                    aria-label="Default select example"
                    name="payType"
                    value={paySetting.payType}
                    onChange={(e) =>
                      setPaySetting({
                        ...paySetting,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    {["Net Pay", "Gross Pay"].map((a, i) => (
                      <option key={i} value={a}>
                        {a}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Working Days</label>
                    <input
                      onChange={(e) =>
                        setPaySetting({
                          ...paySetting,
                          [e.target.name]: e.target.value,
                        })
                      }
                      className="form-control"
                      name="workingDays"
                      value={paySetting.workingDays || ""}
                      type="number"
                    />
                  </div>
                </div>
              </div>
              <div>
                <Button onClick={savePaySetting} type="submit">
                  {btnText}
                </Button>
              </div>
              {/*    <ImportPaylistFromExcel /> */}
            </div>

            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
      <ModalLoader show={loading} />
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
    settings: state.settingsReducer.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAllSettings: (settings) => dispatch(addAllSettings(settings)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PayrollSettings));
