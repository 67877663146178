import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";

class Timesheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>Invex ERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col">
                  <h4 className="page-title">Timesheet</h4>
                </div>
                {/* <div className="col-12 text-right m-b-30">
							<a href="#" className="btn add-btn" data-toggle="modal" data-target="#add_todaywork"><i className="fa fa-plus"></i> Add Today Work</a>
						</div> */}
              </div>
              {/*<!-- /Page Title -->*/}

              <div className="row">
                <div className="col-12">
                  <div className="text-center">
                    <img src="/assets/img/broken.png" alt="broken-img" />
                    <h2>Time Attendance not linked yet.</h2>
                  </div>
                </div>
              </div>
              {/* <div className="row">
              
						<div className="col-md-12">
							<div className="table-responsive">
								<table className="table table-striped custom-table mb-0 datatable">
									<thead>
										<tr>
											<th>Employee</th>
											<th>Date</th>
											<th>Projects</th>
											<th className="text-center">Assigned Hours</th>
											<th className="text-center">Hours</th>
											<th className="d-none d-sm-table-cell">Description</th>
											<th className="text-right">Actions</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-02.jpg" /></a>
													<a href="profile.html">John Doe <span>Web Designer</span></a>
												</h2>
											</td>
											<td>8 Mar 2019</td>
											<td>
												<h2>Office Management</h2>
											</td>
											<td className="text-center">20</td>
											<td className="text-center">7</td>
											<td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-09.jpg" /></a>
													<a href="profile.html">Richard Miles <span>Web Developer</span></a>
												</h2>
											</td>
											<td>8 Mar 2019</td>
											<td>
												<h2>Project Management</h2>
											</td>
											<td className="text-center">20</td>
											<td className="text-center">12</td>
											<td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-10.jpg" /></a>
													<a href="profile.html">John Smith <span>Android Developer</span></a>
												</h2>
											</td>
											<td>8 Mar 2019</td>
											<td>
												<h2>Video Calling App</h2>
											</td>
											<td className="text-center">20</td>
											<td className="text-center">12</td>
											<td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-05.jpg" /></a>
													<a href="profile.html">Mike Litorus <span>IOS Developer</span></a>
												</h2>
											</td>
											<td>8 Mar 2019</td>
											<td>
												<h2>Hospital Administration</h2>
											</td>
											<td className="text-center">20</td>
											<td className="text-center">12</td>
											<td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-11.jpg" /></a>
													<a href="profile.html">Wilmer Deluna <span>Team Leader</span></a>
												</h2>
											</td>
											<td>8 Mar 2019</td>
											<td>
												<h2>Office Management</h2>
											</td>
											<td className="text-center">20</td>
											<td className="text-center">7</td>
											<td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-12.jpg" /></a>
													<a href="profile.html">Jeffrey Warden <span>Web Developer</span></a>
												</h2>
											</td>
											<td>8 Mar 2019</td>
											<td>
												<h2>Project Management</h2>
											</td>
											<td className="text-center">20</td>
											<td className="text-center">12</td>
											<td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-13.jpg" /></a>
													<a href="profile.html">Bernardo Galaviz <span>Web Developer</span></a>
												</h2>
											</td>
											<td>8 Mar 2019</td>
											<td>
												<h2>Video Calling App</h2>
											</td>
											<td className="text-center">20</td>
											<td className="text-center">12</td>
											<td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-01.jpg" /></a>
													<a href="profile.html">Lesley Grauer <span>Team Leader</span></a>
												</h2>
											</td>
											<td>8 Mar 2019</td>
											<td>
												<h2>Hospital Administration</h2>
											</td>
											<td className="text-center">20</td>
											<td className="text-center">12</td>
											<td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-16.jpg" /></a>
													<a href="profile.html">Jeffery Lalor <span>Team Leader</span></a>
												</h2>
											</td>
											<td>8 Mar 2019</td>
											<td>
												<h2>Ware house developement</h2>
											</td>
											<td className="text-center">20</td>
											<td className="text-center">9</td>
											<td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-04.jpg" /></a>
													<a href="profile.html">Loren Gatlin <span>Android Developer</span></a>
												</h2>
											</td>
											<td>8 Mar 2019</td>
											<td>
												<h2>Office Management</h2>
											</td>
											<td className="text-center">20</td>
											<td className="text-center">12</td>
											<td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-03.jpg" /></a>
													<a href="profile.html">Tarah Shropshire <span>Android Developer</span></a>
												</h2>
											</td>
											<td>8 Mar 2019</td>
											<td>
												<h2>Project Management</h2>
											</td>
											<td className="text-center">20</td>
											<td className="text-center">12</td>
											<td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-08.jpg" /></a>
													<a href="profile.html">Catherine Manseau <span>Android Developer</span></a>
												</h2>
											</td>
											<td>8 Mar 2019</td>
											<td>
												<h2>Video Calling App</h2>
											</td>
											<td className="text-center">20</td>
											<td className="text-center">12</td>
											<td className="d-none d-sm-table-cell col-md-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque.</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_todaywork"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_workdetail"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>*/}
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Add Today Work Modal -->*/}
            <div
              id="add_todaywork"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Today Work details</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label>
                            Project <span className="text-danger">*</span>
                          </label>
                          <select className="select">
                            <option>Office Management</option>
                            <option>Project Management</option>
                            <option>Video Calling App</option>
                            <option>Hospital Administration</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-4">
                          <label>
                            Deadline <span className="text-danger">*</span>
                          </label>
                          <div className="cal-icon">
                            <input
                              className="form-control"
                              type="text"
                              value="5 May 2019"
                              readonly
                            />
                          </div>
                        </div>
                        <div className="form-group col-sm-4">
                          <label>
                            Total Hours <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value="100"
                            readonly
                          />
                        </div>
                        <div className="form-group col-sm-4">
                          <label>
                            Remaining Hours{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value="60"
                            readonly
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label>
                            Date <span className="text-danger">*</span>
                          </label>
                          <div className="cal-icon">
                            <input
                              className="form-control datetimepicker"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group col-sm-6">
                          <label>
                            Hours <span className="text-danger">*</span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          Description <span className="text-danger">*</span>
                        </label>
                        <textarea rows="4" className="form-control"></textarea>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Add Today Work Modal -->*/}

            {/*<!-- Edit Today Work Modal -->*/}
            <div
              id="edit_todaywork"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Edit Work Details</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label>
                            Project <span className="text-danger">*</span>
                          </label>
                          <select className="select">
                            <option>Office Management</option>
                            <option>Project Management</option>
                            <option>Video Calling App</option>
                            <option>Hospital Administration</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-4">
                          <label>
                            Deadline <span className="text-danger">*</span>
                          </label>
                          <div className="cal-icon">
                            <input
                              className="form-control"
                              type="text"
                              value="5 May 2019"
                              readonly
                            />
                          </div>
                        </div>
                        <div className="form-group col-sm-4">
                          <label>
                            Total Hours <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value="100"
                            readonly
                          />
                        </div>
                        <div className="form-group col-sm-4">
                          <label>
                            Remaining Hours{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value="60"
                            readonly
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label>
                            Date <span className="text-danger">*</span>
                          </label>
                          <div className="cal-icon">
                            <input
                              className="form-control datetimepicker"
                              value="03/03/2019"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group col-sm-6">
                          <label>
                            Hours <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value="9"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          Description <span className="text-danger">*</span>
                        </label>
                        <textarea rows="4" className="form-control">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Donec vel elit neque.
                        </textarea>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Edit Today Work Modal -->*/}

            {/*<!-- Delete Today Work Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_workdetail"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Work Details</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary continue-btn"
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- Delete Today Work Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Timesheet));
