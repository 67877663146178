import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import { Dropdown } from "react-bootstrap";
import { CSVLink } from "react-csv";
import currency from "currency.js";
import moment from "moment";

import classnames from "classnames";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import Loader from "../Loader";
import { branch_id } from "../../utils";
import { PDFIcon, ExcelIcon, ExportIcon, SummaryCloseIcon } from "../icons";
import { useDownloadExcel } from "../useDownloadExcel";
import { allMonths } from "../../utils";

function AllowanceReports() {
  const date = new Date();
  const [activeTab, setActiveTab] = useState("1");
  const [deductions, setDeductions] = useState([]);
  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: date.getMonth() + 1,
    payrollYear: date.getFullYear(),
  });
  const [payroll, setPayroll] = useState([]);

  const [totalNetpay, setTotalNetPay] = useState(0);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [isLoading, setIsLoading] = useState(false);

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  useEffect(() => {
    getPayroll();

    return () => {};
  }, [payrollDate]);

  async function getPayroll() {
    try {
      setIsLoading(true);
      const token = "dummy token";
      const payrollResonse = await fetch(
        `${URLS.backendPayroll}/get-payroll/${branch_id}?payrollMonth=${payrollDate.payrollMonth}&payrollYear=${payrollDate.payrollYear}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      const payrollResult = await payrollResonse.json();
      const { payroll } = payrollResult;

      if (payroll.length) {
        setDeductions(payroll[0].allowances);
        setActiveTab(payroll[0].allowances[0].slug);
        setPayroll(payroll);
        const total = payroll
          .map((el) => {
            return el.allowances.map((p) => parseFloat(p.total));
          })
          .flat()
          .reduce((cur, pre) => parseFloat(cur) + parseFloat(pre));

        setTotalNetPay(total);
      } else {
        setPayroll([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const onDownloadExcelData = async () => {
    let headers = deductions.map((setting, index) => [setting.name]);

    headers = ["No", "Name", ...headers];

    let exData = payroll.map((employee, index) => [
      index + 1,
      employee.user?.personalInfo?.name.first +
        " " +
        employee.user?.personalInfo?.name.last,
      ...employee.allowances.map((deduction) => deduction.total),
    ]);

    const date = `Date Prepared: ${moment(new Date()).format(
      "MMMM DD yyyy k:mm:ss z"
    )}`;

    const total = payroll
      .map((el) => {
        return el.allowances.map((p) => parseFloat(p.total));
      })
      .flat()
      .reduce((cur, pre) => parseFloat(cur) + parseFloat(pre));

    exData = [
      // [company],
      [
        `Allowance Report for ${allMonths[payrollDate.payrollMonth - 1]} ${
          payrollDate.payrollYear
        }`,
      ],
      [date],
      [""],
      headers,
      ...exData,
      [""],
      ["Allowance Summary"],
      [
        "Total Allowance Amount",
        currency(total, {
          symbol: "",
        }).format(),
      ],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12 col-12 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Allowance Reports</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-sm-5 col-5"></div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div className="p-3 col-sm-12 col-12 p-0m">
              {isLoading && <Loader />}
              <div className="d-flex justify-content-between my-4">
                <div className="d-flex">
                  <div className="form-inline">
                    <label>Month</label>
                    <select
                      className="form-control mx-2"
                      value={payrollDate.payrollMonth}
                      onChange={(e) => {
                        setPayrollDate({
                          payrollMonth: e.target.value,
                          payrollYear: payrollDate.payrollYear,
                        });
                      }}
                    >
                      {[...Array(12)].map((e, index) => (
                        <option key={index}>{index + 1}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-inline ml-3">
                    <label>Year</label>
                    <input
                      type="number"
                      style={{ width: "100px" }}
                      className="form-control mx-2"
                      value={payrollDate.payrollYear}
                      onChange={(e) => {
                        setPayrollDate({
                          payrollMonth: payrollDate.payrollMonth,
                          payrollYear: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <div className="mx-4">
                    <CSVLink
                      className="btn print d-none"
                      filename={`Allowance Report(${moment(new Date()).format(
                        "DD-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />

                    {deductions.length > 0 && (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="print"
                          disabled={isfetchingExcel}
                          bsPrefix=""
                        >
                          <span className="mx-2">Export</span>
                          <ExportIcon color="#008000" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className="text-center"
                        >
                          <Dropdown.Item
                            as="div"
                            onClick={onDownloadExcelData}
                            className="p-cursor"
                          >
                            Excel <ExcelIcon color="#008000" />
                          </Dropdown.Item>
                          <Dropdown.Item as="div">
                            <a
                              href={`${URLS.backendPayroll}/get-payroll/PDF/${branch_id}?payrollMonth=${payrollDate.payrollMonth}&payrollYear=${payrollDate.payrollYear}&doc=allowances`}
                              target="blank"
                              className="text-dark"
                            >
                              PDF
                              <PDFIcon color="#ff0000" />
                            </a>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                  <button
                    onClick={() => setBtnSummaryActive(!btnSummryActive)}
                    className="btn summaryBtn "
                  >
                    Show Summary
                  </button>
                </div>
              </div>
              {deductions.length > 0 && (
                <Nav tabs>
                  {deductions.map((deduction, index) => {
                    return (
                      <NavItem key={index}>
                        <NavLink
                          className={`p-cursor ${classnames({
                            active: activeTab === deduction.slug,
                          })}`}
                          onClick={() => {
                            toggle(deduction.slug);
                          }}
                        >
                          <span className="text-capitalize">
                            {deduction.name}
                          </span>
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              )}

              {deductions.length ? (
                <TabContent activeTab={activeTab} className="pt-0">
                  {deductions.map((deduction, index) => {
                    return (
                      <TabPane tabId={deduction.slug} key={index}>
                        <Row>
                          <div className="col-md-12 w-100">
                            <div className="table-responsive">
                              <table className="table table-striped custom-table mb-0 datatable">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Name</th>

                                    {deductions.map((setting, index) => {
                                      return (
                                        <th
                                          className={classnames({
                                            "d-none":
                                              activeTab !== setting.slug,
                                          })}
                                          key={index}
                                        >
                                          <span className="text-capitalize">
                                            {setting.name}
                                          </span>
                                        </th>
                                      );
                                    })}
                                  </tr>
                                </thead>
                                <tbody>
                                  {payroll.map((employee, index) => {
                                    return (
                                      <tr key={index}>
                                        <th>{index + 1}</th>
                                        <th>
                                          {employee.user?.personalInfo?.name
                                            .first +
                                            " " +
                                            employee.user?.personalInfo?.name
                                              .last}
                                        </th>

                                        {employee.allowances.map(
                                          (deduction, index) => {
                                            return (
                                              <th
                                                key={index}
                                                className={classnames({
                                                  "d-none":
                                                    activeTab !==
                                                    deduction.slug,
                                                })}
                                              >
                                                {deduction.total}
                                              </th>
                                            );
                                          }
                                        )}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Row>
                      </TabPane>
                    );
                  })}
                </TabContent>
              ) : (
                <div
                  className="w-100 d-flex justify-content-center align-items-center bg-white"
                  style={{ height: "250px" }}
                >
                  <p>No Records</p>
                </div>
              )}
            </div>

            <div
              className={
                btnSummryActive
                  ? `summaryNew border-top open`
                  : `summaryNew border-top`
              }
            >
              <div className="row justify-content-between mt-4">
                <div className="col">
                  <div className="my-3">
                    <div className="d-flex gap-3 align-items-center mb-4">
                      <h4 className="m-0">Total Allowance Amount</h4>{" "}
                      <hr className="flex-grow-1 m-0" />
                      {btnSummryActive && (
                        <button
                          onClick={() => setBtnSummaryActive(!btnSummryActive)}
                          className="btn p-0"
                        >
                          <SummaryCloseIcon />
                        </button>
                      )}
                    </div>

                    <div
                      className="gridCont mb-3 three-col-grid"
                      style={{ gridTemplateRows: "unset" }}
                    >
                      {/*  */}
                      <div className="gridChild gridChildBorderLeftBlue">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {deductions.length > 0
                              ? currency(totalNetpay, {
                                  symbol: "",
                                }).format()
                              : currency(0, {
                                  symbol: "",
                                }).format()}
                          </p>
                          <p className="gridChld2">Total Allowance Amount</p>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AllowanceReports));
