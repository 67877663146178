import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import $ from "jquery";
import "../../assets/css/jobgrade.css";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import currency from "currency.js";
import CurrencyInput from "react-currency-input-field";
import { branch_id } from "../../utils";

export default class AddJobGradeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobGrade: {
        name: "",
        description: "",
        type: "normal",
        branch_id,
        salary: {
          basic: 0,
          housing: 0,
          medical: 0,
          transport: 0,
          utility: 0,
          dressing: 0,
          entertainment: 0,
          offshoreAllowances: 0,
          transitAllowances: 0,
          overTimeAllowances: 0,
        },
      },
      totalGrade: 0,
    };
  }

  updateJobGradeName = (event) => {
    const { jobGrade } = this.state;
    jobGrade.name = event.target.value;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeDescription = (event) => {
    const { jobGrade } = this.state;
    jobGrade.description = event.target.value;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryBasic = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.basic = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryHousing = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.housing = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryMedical = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.medical = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryTransport = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.transport = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryUtility = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.utility = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryDressing = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.dressing = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryEntertainment = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.entertainment = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryOffshoreAllowances = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.offshoreAllowances = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryTransitAllowances = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.transitAllowances = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryOverTimeAllowances = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.overTimeAllowances = event;
    this.setState({
      jobGrade,
    });
  };

  submitJob = (e) => {
    e.preventDefault();
    console.log("in submit");
    const { jobGrade } = this.state;
    const token = "dummy token";
    fetch(`${URLS.backendJobGrades}/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ jobGrade: jobGrade }),
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        if (result.success) {
          this.props.updateJobGradesList(result.jobGrade);
          const grade = Object.entries(result.jobGrade.salary).reduce(
            (c, p) => c + p[1],
            0
          );
          this.props?.putJobGrade && this.props.putJobGrade(grade);
          this.props.changeShowJobState();

          toast("New job grade added", {
            type: "success",
          });
        } else {
          this.props.changeShowJobState();
          const e = Error(result.err);
          console.log(e);
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Error new Job Grade not added", {
          type: "error",
        });
      });
  };

  cancelJob = (e) => {
    e.preventDefault();
    console.log("in cancel");
    this.props.changeShowJobState();
    // $('#add_job').hide();
  };

  updateType = (e) => {
    const { jobGrade } = this.state;
    jobGrade.type = e.target.value;
    this.setState({
      jobGrade,
    });
  };

  render() {
    const { jobGrade } = this.state;
    return (
      <div
        id="add_job"
        style={
          this.props.isShow
            ? {
                width: "50%",
                position: "fixed",
                left: "50%",
                marginLeft: "-25%",
                top: "20px",
                zIndex: "2000",
                display: "block",
                backgroundColor: "#eaecef",
                height: "100%",
                borderRadius: "10px",
              }
            : {
                width: "50%",
                position: "fixed",
                left: "50%",
                marginLeft: "-25%",
                top: "20px",
                zIndex: "2000",
                display: "none",
                backgroundColor: "#eaecef",
                height: "100%",
                borderRadius: "10px",
              }
        }
      >
        <Form
          onSubmit={this.submitJob}
          style={{
            overflowY: "scroll",
            maxHeight: "100%",
            // padding: '50px 30px',
            borderRadius: "10px",
          }}
        >
          <Form.Group
            as={Row}
            style={{
              alignItems: "left",
              justifyContent: "left",
              fontSize: "16px",
              color: "#ffffff",
              fontWeight: "bold",
              height: "40px",
              borderRadius: "10px 10px 0 0",
              padding: "10px 15px",
              marginLeft: "0",
              marginRight: "0",
              display: "block",
              backgroundColor: "#5378b2",
              // background: "linear-gradient(to right, #00c5fb 0%, #0253cc 100%)",
            }}
          >
            <span
              style={{
                display: "inline-block",
                height: "20px",
                lineHeight: "20px",
                float: "left",
              }}
            >
              Create New Job Grade
            </span>
            <span
              style={{
                display: "inline-block",
                height: "10px",
                float: "right",
                // padding: "2px 10px",
                padding: "5px",
                position: "relative",
              }}
            >
              <button
                className="btn btn-info"
                style={{
                  padding: "2px 10px",
                  position: "absolute",
                  top: -2,
                  right: "10px",
                  background: "#ffffff",
                  color: "#000000",
                  borderRadius: "20px",
                  border: "none",
                }}
                onClick={this.cancelJob}
              >
                x
              </button>
            </span>
          </Form.Group>
          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              "padding-left": "10px",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Job Grade
            </Form.Label>
            <Col sm={6}>
              <Form.Control
                value={jobGrade.name}
                onChange={this.updateJobGradeName}
              />
            </Col>
          </Form.Group>
          {/* <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              "padding-left": "10px",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Salary Type
            </Form.Label>
            <Col sm={6}>
              <Form.Control
                as="select"
                custom
                required
                onChange={this.updateType}
              >
                <option value="">Select Salary type</option>
                <option value="prorated">Prorated</option>
                <option value="normal">Normal</option>
              </Form.Control>
            </Col>
          </Form.Group> */}
          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              "padding-left": "10px",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Description
            </Form.Label>
            <Col sm={6}>
              <textarea
                className="form-control"
                rows="5"
                value={jobGrade.description}
                onChange={this.updateJobGradeDescription}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            style={{
              backgroundColor: "#5378b2",
              alignItems: "left",
              justifyContent: "left",
              fontSize: "16px",
              color: "#ffffff",
              fontWeight: "bold",
              height: "40px",
              padding: "10px 15px",
              marginLeft: "0",
              marginRight: "0",
              display: "block",
            }}
          >
            Job Grade Salary Settings
          </Form.Group>

          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              "padding-left": "10px",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Basic
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={jobGrade.salary.basic}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) =>
                  this.updateJobGradeSalaryBasic(value)
                }
              />
            </Col>
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Housing
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={jobGrade.salary.housing}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) =>
                  this.updateJobGradeSalaryHousing(value)
                }
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              "padding-left": "10px",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Medical
            </Form.Label>

            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={jobGrade.salary.medical}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) =>
                  this.updateJobGradeSalaryMedical(value)
                }
              />
            </Col>
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Transport
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={jobGrade.salary.transport}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) =>
                  this.updateJobGradeSalaryTransport(value)
                }
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              "padding-left": "10px",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Utility
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={jobGrade.salary.utility}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) =>
                  this.updateJobGradeSalaryUtility(value)
                }
              />
            </Col>
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Dressing
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={jobGrade.salary.dressing}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) =>
                  this.updateJobGradeSalaryDressing(value)
                }
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              "padding-left": "10px",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              <b>GROSS SALARY</b>
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                value={currency(jobGrade.salary.basic, {
                  symbol: "",
                })
                  .add(jobGrade.salary.housing)
                  .add(jobGrade.salary.medical)
                  .add(jobGrade.salary.transport)
                  .add(jobGrade.salary.utility)
                  .add(jobGrade.salary.dressing)
                  .add(jobGrade.salary.entertainment)
                  .format()}
                disabled
              />
            </Col>

            <Form.Label
              style={{ fontSize: "14px" }}
              column
              // sm={{ span: 2, offset: 6 }}
              sm={2}
            >
              Entertainment
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={jobGrade.salary.entertainment}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) =>
                  this.updateJobGradeSalaryEntertainment(value)
                }
              />
            </Col>
          </Form.Group>

          <div className="d-flex justify-content-center p-4">
            <button
              style={{
                borderRadius: "7px",
                backgroundColor: "#5378b2",
                border: "none",
              }}
              className="btn btn-primary submit-btn"
            >
              Create Now
            </button>
          </div>
          {/* <Form.Group
            as={Row}
            style={{
              backgroundColor: "#5378b2",
              alignItems: "left",
              justifyContent: "left",
              fontSize: "16px",
              color: "#ffffff",
              fontWeight: "bold",
              height: "40px",
              padding: "10px 15px",
              marginLeft: "0",
              marginRight: "0",
              display: "block",
            }}
          >
            Other Allowance
          </Form.Group>
          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              "padding-left": "10px",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Offshore Allowance
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={jobGrade.salary.offshoreAllowances}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) =>
                  this.updateJobGradeSalaryOffshoreAllowances(value)
                }
              />
            </Col>
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Transit Allowance
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={jobGrade.salary.transitAllowances}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) =>
                  this.updateJobGradeSalaryTransitAllowances(value)
                }
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              "padding-left": "10px",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              OverTime Allowance
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={jobGrade.salary.overTimeAllowances}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) =>
                  this.updateJobGradeSalaryOverTimeAllowances(value)
                }
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            style={{
              marginBottom: "30px",
              marginLeft: "0",
              marginRight: "0",
              paddingRight: "15px",
              justifyContent: "space-between",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              <b>TOTAL NET SALARY</b>
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                value={currency(jobGrade.salary.basic, {
                  symbol: "",
                })
                  .add(jobGrade.salary.housing)
                  .add(jobGrade.salary.medical)
                  .add(jobGrade.salary.transport)
                  .add(jobGrade.salary.utility)
                  .add(jobGrade.salary.paidLeave)
                  .add(jobGrade.salary.gratuity)
                  .add(jobGrade.salary.offshoreAllowances)
                  .add(jobGrade.salary.transitAllowances)
                  .add(jobGrade.salary.overTimeAllowances)
                  .format()}
                disabled
              />
            </Col>

            <button
              style={{
                borderRadius: "7px",
                backgroundColor: "#5378b2",
                border: "none",
              }}
              className="btn btn-primary submit-btn"
            >
              Create Now
            </button>
          </Form.Group> */}
        </Form>
      </div>
    );
  }
}
